import '../styles/lab.css';

// import and instantiate vue 3
import { createApp } from 'vue';
import ContactForm from './components/Contact/ContactForm.vue';
import EmailCaptureForm from './components/Common/EmailCaptureForm.vue';
import CatalogRequestForm from './components/Catalogs/CatalogRequestForm.vue';
import QuestionAnwerAccordion from './components/FAQ/QuestionAnswerAccordion.vue';
import TaxonomyDropdown from './components/Common/TaxonomyDropdown.vue';
import VerticalTabs from './components/Tabs/VerticalTabs.vue';
import HorizontalTabs from './components/Tabs/HorizontalTabs.vue';
import WhatWeDoTabs from './components/Home/WhatWeDoTabs.vue';
import BeliefsOverlay from './components/Home/BeliefsOverlay.vue';
import SEOLandingNav from './components/SEOLanding/SEOLandingNav.vue';
import LightboxImg from './components/Common/LightboxImg.vue';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";


// create vue app on
const app = createApp({
    data() {
        return {
            navOpen: false,
            mobileNavOpen: false,
            navInteractiveThreshold: 100,
            pageIsScrolled: false,
            prevScrollPos: 0,
            beliefsOverlayVisible: false,
            beliefsThumbnailVisible: true,
        }
    },
    methods: {
        toggleNav() {
            this.navOpen = !this.navOpen;
        },
        watchScroll() {
            let currentPos = window.scrollY;
            let movingUp = currentPos < this.prevScrollPos;
            this.pageIsScrolled = currentPos > this.navInteractiveThreshold;
            this.navOpen = movingUp && this.pageIsScrolled;
            this.prevScrollPos = currentPos;
        },
        toggleMobileNav() {
            this.mobileNavOpen = !this.mobileNavOpen;


            if(this.mobileNavOpen) {
                document.body.classList.add('overflow-hidden');
            }else{
                document.body.classList.remove('overflow-hidden');
            }
        },
        toggleBeliefsOverlay() {
            this.beliefsOverlayVisible = !this.beliefsOverlayVisible;
        },
        hideBeliefsThumbnail() {
            document.cookie = "hideBeliefsThumbnail=true; max-age=86400";
            this.beliefsThumbnailVisible = false;
        },
        shouldShowBeliefsThumbnail() {
            return document.cookie.indexOf('hideBeliefsThumbnail') === -1;
        }
    },
    computed: {
        navWrapperClass() {
            return {
                '!bg-opacity-100 shadow-sm': this.pageIsScrolled ? true : false,
                '-translate-y-28': ! this.navOpen && this.pageIsScrolled ? true : false,
            }
        },
        navClass() {
            return {
                '': ! this.pageIsScrolled,
                '!text-black': this.pageIsScrolled,
            }
        }
    },
    mounted() {
        document.addEventListener('DOMContentLoaded', this.watchscroll);
        window.addEventListener('scroll', this.watchScroll);
        Fancybox.bind("[data-fancybox]");

        if(this.mobileNavOpen) {
            document.body.classList.add('overflow-hidden');
        }

        if(!this.shouldShowBeliefsThumbnail()) {
            this.beliefsThumbnailVisible = false;
        }
    }
});

app.component('catalog-request-form', CatalogRequestForm);
app.component('contact-form', ContactForm);
app.component('email-capture-form', EmailCaptureForm);
app.component('question-answer-accordion', QuestionAnwerAccordion);
app.component('taxonomy-dropdown', TaxonomyDropdown);
app.component('vertical-tabs', VerticalTabs);
app.component('horizontal-tabs', HorizontalTabs);
app.component('what-we-do-tabs', WhatWeDoTabs);
app.component('beliefs-overlay', BeliefsOverlay);
app.component('seo-landing-nav', SEOLandingNav);
app.component('lightbox-img', LightboxImg);

// mount vue app
app.mount('#app');
