<template>
    <div>
        <div class="flex justify-between items-start py-5 cursor-pointer" @click="toggle()">
            <dt class="flex-shrink text-base font-normal" v-text="question"></dt>
            <div :class="{ 'bg-red-600 text-white': ! opened, 'bg-white text-black': opened }"
                class="flex-shrink-0 flex justify-center items-center cursor-pointer w-8 h-8 border border-red-500 text-black leading-3 group-hover:bg-red-500 group-hover:text-white transition-colors">
                <span :class="{ 'hidden': !opened }" class="leading-none -mt-0.5 font-bold">&#8212;</span>
                <span :class="{ 'hidden': opened }" class="leading-none -mt-0.5">+</span>
            </div>
        </div>
        <dd :class="{ 'hidden': !opened }" class="pb-5 text-md font-light text-neutral-700 user-content" v-html="answer"></dd>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
    question: { 
        type: String, 
        required: true 
    },
    answer: { 
        type: String, 
        required: true 
    },
});

const opened = ref(false);

/**
 * Open the answer
 */
function open() {
    opened.value = true;
}

/**
 * Close the answer
 */
function close() {
    opened.value = false;
}

/**
 * Toggle an answer
 */
function toggle() {
    if(opened.value == true){
        close();
    } else {
        open();
    }
}
</script>
