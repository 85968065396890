<template>
    <div :class="backgroundColor" class="relative p-8 sm:p-12">
        <div>
            <h3 :class="{'text-white': foregroundIsLight, 'text-neutral-900': ! foregroundIsLight}" class="h3">{{ title }}</h3>
            <p :class="{'text-white': foregroundIsLight, 'text-neutral-900': ! foregroundIsLight}" class="p text-sm">{{ description }}</p>
        </div>

        <template v-if="success">
            <div v-html="success"></div>
        </template>
        <template v-else>
            <div v-if="error" class="text-red-500">{{ error }}</div>

            <form method="post" action @submit.prevent="submit">
                <div class="grid grid-cols-12 gap-6 mt-6">
                    <ContactFormField v-for="field in fields" v-model="values['input_' + field.id]" :foreground-is-light="foregroundIsLight" :field="field" :disabled="loading"></ContactFormField>
                </div>

                <div class="mt-6">
                    <button type="submit" class="bg-white text-neutral-900 uppercase font-headline text-md font-semibold tracking-widest flex py-3 px-5 items-center hover:bg-neutral-950 hover:text-white transition-colors">
                        <div v-if="loading"><LoadingIcon class="w-4 h-4 mr-2" /></div>
                        <div class="-mb-1.5">{{ form.button.text }}</div>
                        <span class="text-lg ml-12 translate-y-0.5"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" /></svg></span>
                    </button>
                </div>
            </form>
        </template>
    </div>

    <!-- <pre class="mt-24 bg-zinc-100 p-2 text-[10px]">{{ values }}</pre> -->
    <!-- <pre class="mt-24 bg-zinc-100 p-2 text-[10px]">{{ form }}</pre> -->
</template>

<script setup>
import { ref } from 'vue';
import ContactFormField from './ContactFormField.vue';
import LoadingIcon from '../Common/LoadingIcon.vue';
import axios from 'axios';

const props = defineProps({
    title: { type: String, default: 'Get in Touch' },
    description: { type: String, default: 'Your email address will not be published. Required fields are marked *' },
    form: { required: true },
    backgroundColor: { type: String, default: 'bg-red-600' },
    foregroundIsLight: { type: Boolean, default: true },
});

const fields = ref(props.form.fields);
const values = ref({});
// const values = ref({ input_1: 'Flywheel Co.', input_3: 'chris+lab@flywheel.co', input_4: '123-456-7890', input_5: 'Flywheel Co.', input_6: 'Testing, testing, testing...' });
const loading = ref(false);
const error = ref(false);
const success = ref(false);

/**
 * Submit the form data to the REST api endpoint.
 */
function submit() {
    loading.value = true;
    clearErrors();

    axios
        .post('/wp-json/lab/v1/contact', values.value)
        .then(({ data }) => handleResponse(data))
        .catch((err) => handleUnknownError(err))
        .then(() => (loading.value = false));
}

/**
 * Handles a non-error response.
 *
 * @param {Object} data
 */
function handleResponse(data) {
    if (!data.is_valid) {
        if (data.validation_messages) {
            error.value = 'Please see the highlighted errors below:';
            handleValidationErrors(data.validation_messages);
        } else {
            handleUnknownError(data);
        }
    } else {
        success.value = data.confirmation_message;
    }
}

/**
 * Clear all errors.
 */
function clearErrors() {
    error.value = false;
    fields.value.forEach((field) => (field.error = null));
}

/**
 * Add an error to an individual fields.
 *
 * @param {Number} id
 * @param {String} message
 */
function addFieldError(id, message) {
    const field = fields.value.findIndex((f) => f.id == id);

    fields.value[field].error = message;
}

/**
 * Handle validation-level errors.
 *
 * @param {Object} errors
 */
function handleValidationErrors(errors) {
    Object.keys(errors).forEach((key) => addFieldError(key, errors[key]));
}

/**
 * Handle an unexpected error.
 *
 * @param {*} error
 */
function handleUnknownError(error) {
    error.value = 'An unknown error has occurred.';
}
</script>
