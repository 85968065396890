<template>
    <TransitionRoot :show="visible" as="div" id="five-beliefs-overlay" class="fixed inset-0 top-0 bottom-0 z-50" tabindex="0"
        @keydown.esc="$emit('closeBeliefsOverlay')">

        <TransitionChild enter="transition-all duration-500" enter-from="opacity-0" enter-to="opacity-100"leave="transition-all duration-400" leave-from="opacity-100" leave-to="opacity-0">
            <div class="absolute top-0 left-0 w-full h-full bg-neutral-900 bg-opacity-80 z-20" @click.self="$emit('closeBeliefsOverlay')"></div>
        </TransitionChild>

        <TransitionChild as="div" enter="transition-all duration-800"  enter-from="opacity-0" enter-to="opacity-100"  leave="transition-all duration-200" leave-from="opacity-100" leave-to="opacity-0" 
            class="fixed max-w-7xl w-[95vw] top-5 md:top-20 left-1/2 -translate-x-1/2 p-3 sm:p-10 pt-14 rounded-lg bg-white shadow-xl z-30"
        >

            <div @click.prevent="$emit('closeBeliefsOverlay')" class="absolute top-2 right-2 cursor-pointer text-center p-5 text-neutral-600 hover:bg-neutral-100 hover:text-red-600 transition-all rounded">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="size-5">
                    <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
                </svg>
            </div>
            
            <div class="text-center xl:my-6">
                <h3 class="text-4xl md:text-5xl xl:text-6xl text-red-600 font-headline m-0 leading-8">5 beliefs <span class="text-neutral-800">that erode your earnings</span></h3>
            </div>
            <nav class="py-4 px-0 md:pt-5 md:px-20 2xl:px-32" ref="beliefs-nav">
                <ul class="relative w-full overflow-x-auto flex justify-center text-xs sm:text-sm uppercase font-headline tracking-wider sm:font-bold text-neutral-600 gap-1 sm:gap-4 xl:gap-10">
                    <div class="absolute left-0 right-0 h-1 bg-neutral-100 top-1/2 z-10"></div>
                    <li v-for="(tab, i) in tabs" @click="setActiveTab(i)"
                        :class="{ 
                            'text-white bg-red-600 hover:bg-red-600': tabIsActive(i), 
                            // 'bg-neutral-200': i == activeTab + 1,
                            'bg-neutral-50 text-neutral-400': i < activeTab,
                        }"
                        class="flex-grow cursor-pointer text-center pt-1 pb-0 px-1 md:px-3 md:pt-3 md:pb-2 whitespace-nowrap rounded-full bg-neutral-100 transition-all z-20">
                        {{ tab }}
                    </li>
                </ul>
            </nav>

            <main class="relative max-w-6xl h-[60vh] md:min-h-[60vh] overflow-auto no-scrollbar mx-2 md:mx-16 lg:mx-28 xl:mx-40 md:py-10 flex xl:items-center">
                
                <TransitionRoot v-for="(section, k) in sections" appear :show="tabIsActive(k)" as="section" 
                    class="w-full h-full pt-5 md:pt-0 flex flex-col lg:flex-row items-start gap-8 md:gap-14" 
                    enter="sm:top-10 absolute transition-all duration-500" :enter-from="(direction == 'next' ? 'translate-x-full opacity-0' : '-translate-x-full opacity-0')" enter-to="opacity-100 translate-x-0" 
                    leave="sm:top-10 absolute transition-all duration-500" leave-from="opacity-100 translate-x-0" :leave-to="(direction == 'next' ? 'opacity-0 -translate-x-full' : 'opacity-0 translate-x-full')">
                    
                    <img :src="section.img" :alt="'Image representing ' + section.subtitle + ' belief'" class="hidden lg:block md:w-1/2 lg:w-1/4 flex-shrink-0 object-cover object-center aspect-1 aspect-square rounded-full" />

                    <div class="user-content flex-shrink pb-20 h-full overflow-auto no-scrollbar">
                        <h6 v-text="section.subtitle"></h6>
                        <h2 class="sm:!text-[2.4em] !xl:text-[2.8em] !leading-[1em] text-neutral-900" v-text="section.title"></h2>
                        <div v-if="section.tagline" class="h5 !tracking-wide mt-2 mb-5 text-neutral-700" v-text="section.tagline"></div>
                        <div class="text-sm leading-6 text-neutral-700" v-html="section.content"></div>
                        <a v-if="k == tabs.length - 1" href="/contact" class="button-blue rounded !no-underline">Contact The Lab for a demo</a>
                    </div>
                </TransitionRoot>
            </main>

            <div @click="prevTab" v-show="previousTabVisible"
                class="absolute flex items-center space-x-4 py-2 px-4 bg-neutral-600 hover:bg-neutral-700 text-white cursor-pointer uppercase font-light text-xs -left-2 bottom-0 md:bottom-auto md:top-1/2 -translate-y-1/2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-5">
                    <path fill-rule="evenodd"
                        d="M11.03 3.97a.75.75 0 0 1 0 1.06l-6.22 6.22H21a.75.75 0 0 1 0 1.5H4.81l6.22 6.22a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 0 1 1.06 0Z"
                        clip-rule="evenodd" />
                </svg>
            </div>
            <div @click="nextTab" v-show="nextTabVisible"
                class="absolute flex items-center space-x-4 py-2 px-4 bg-red-600 hover:bg-red-500 text-white cursor-pointer uppercase font-bold font-sans text-xs -right-2  bottom-0 md:bottom-auto md:top-1/2 -translate-y-1/2">
                <span v-text="nextButtonText"></span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-5">
                    <path fill-rule="evenodd"
                        d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                        clip-rule="evenodd" />
                </svg>
            </div>

        </TransitionChild>

    </TransitionRoot>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { TransitionRoot, TransitionChild } from '@headlessui/vue'

defineEmits(['closeBeliefsOverlay'])

const tabs = ['Intro', '#1', '#2', '#3', '#4', '#5', 'The Lab'];
const sections = [
    {
        img: '/app/themes/lab/img/five-beliefs/5B-1.png',
        title: 'Why you\'re closer to your improvement objectives than it appears.',
        subtitle: 'Standardization +  Automation = Transformation',
        tagline: 'Five unchallenged beliefs are slowing your transformation efforts.',
        content: 'Today, white collar, or knowledge workers represent half of all employees. The latest technologies -  automation (RPA), artificial intelligence (AI), and analytics -  promise massive productivity gains. And yet, businesses struggle to apply these technologies to achieve their strategic objectives. Why? Because of persistent, but basic, data and process problems.<br /><br /> They can learn from the experience of manufacturers by quickly adopting standardization, the factory system, and the experience curve (scale advantage). But first, they must challenge several long-held misbeliefs about knowledge work.<br /><br /> Remember: work is work.'
    },
    {
        img: '/app/themes/lab/img/five-beliefs/5B-2.png',
        title: 'Only factory work can be standardized.',
        subtitle: 'Belief #1 - We\'re different',
        tagline: 'Not true. Reject this conventional – and false – division of work.',
        content: 'Take a closer look. Analyze knowledge work and you’ll discover that two-thirds of their work is repetitive and similar. Dig deeper. You’ll find that half of this work is avoidable.<br/> All work has valuable, repeatable patterns, but few have recognized this valuable repetition in knowledge work.'
    },
    {
        img: '/app/themes/lab/img/five-beliefs/5B-3.png',
        title: 'Knowledge work waste is "virtuous" and unavoidable.',
        subtitle: 'Belief #2 - That\'s not waste',
        tagline: 'Waste is waste. Don’t let “virtuous waste” squander hard won earnings.',
        content: 'In a factory, tangible waste like scrap material or machine downtime is obvious. Measuring and reducing it is inarguably valuable.<br /><br /> Beware. Knowledge work waste is intangible and harder to measure – error and data correction, rework, and customer over-service. Workers will claim that they are preserving revenue and serving customers. '
    },
    {
        img: '/app/themes/lab/img/five-beliefs/5B-4.png',
        title: 'The "Factory System" does not apply to knowledge work.',
        subtitle: 'Belief #3 - We\'re not a factory',
        tagline: 'It does. Exploit standardization, specialization, and division of labor.',
        content: 'Over a century ago, craftsmen’s work on the shop floor was treated as unique and “non-routine.” After standardization, specialization, and division of labor – the factory system – productivity increased fifty-fold.<br /><br /> Today, the same untapped wealth hides in plain sight in knowledge work.'
    },
    {
        img: '/app/themes/lab/img/five-beliefs/5B-5.png',
        title: 'Knowledge work is simply not truly "scalable".',
        subtitle: 'Belief #4 - No benefits of scale here',
        tagline: 'Yes, it is. Don\'t squander the experience curve for knowledge work.',
        content: 'In a factory, know-how accumulated over decades - the experience curve - is documented and applied to all tasks to achieve benefits of scale.<br /><br /> In an office, the experience curve exists as unwritten "tribal knowledge".<br /><br /> Tribal knowledge must be embedded in processes, KPIs, and automations. Realize the scale benefits of the knowledge work experience curve.'
    },
    {
        img: '/app/themes/lab/img/five-beliefs/5B-6.png',
        subtitle: 'Belief #5 - New tech will do it',
        title: 'Technology will standardize and automate knowledge work.',
        tagline: 'It will not. You must standardize and simplify first.',
        content: 'When manufacturers automate, they first make the tasks standard and simple for machines to handle - termed "design for machinability."<br /><br /> Knowledge workers rarely automate - they leave that to the IT department.<br /><br /> Adopt a "process-first" automation approach for knowledge work. Standardize and simplify before automating - "design for machinability".'
    },
    {
        img: '/app/themes/lab/img/five-beliefs/5B-7.png',
        subtitle: 'The Lab',
        title: 'Accelerate your transformation efforts with help from The Lab',
        tagline: false,
        content: 'If you are tired of sitting out the recent waves of automation, AI, and analytics, then contact The Lab. We can help you to get started with as much - or as little - as you feel comfortable with.<br /><br /> We\'ve standardized our options for every industry, enabling you to start small and add on - until you\'ve automated the entire enterprise.<br /><br /> For over three decades, The Lab has successfully implemented initiatives for the Fortune 500 and on down.<br /><br /> Call or write today and arrange your 30-minute, screen sharing demo.'
    },
];

const props = defineProps({
    visible: Boolean
});

const activeTab = ref(0);
const direction = ref('next');


watch(() => props.visible, (value) => {
    if (value) {
        reset();
    }
});

function tabIsActive(i) {
    return i == activeTab.value;
}

function setActiveTab(i) {
    if (activeTab.value > i) {
        direction.value = 'prev';
    } else {
        direction.value = 'next';
    }

    activeTab.value = i;
}

function nextTab() {
    if (activeTab.value >= tabs.length - 1) return;

    direction.value = 'next';

    activeTab.value++;
}

function prevTab() {
    if (activeTab.value == 0) return;

    direction.value = 'prev';

    activeTab.value--;
}

function reset() {
    activeTab.value = 0;
}

const previousTabVisible = computed(() => {
    return activeTab.value > 0;
});

const nextTabVisible = computed(() => {
    return activeTab.value < tabs.length - 1;
});

const nextButtonText = computed(() => {
    return activeTab.value < 5 ? 'Go To Belief #' + (parseInt(activeTab.value) + 1) : 'The Lab';
});


</script>