<template>
    <div class="sm:sticky sm:top-20 z-40">
        <div class="bg-white py-3 px-3 rounded shadow-xl sm:shadow-none transition-all"
            :class="{ 'mt-14': isVisible }" @click="toggleOutlineMenu">
            <div class="flex justify-between items-center">
                <h3 class="h5 sm:h3 -mb-2">Outline</h3>
                <button class="sm:hidden">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6" v-if="!isVisible">
                        <path fill-rule="evenodd"
                            d="M2.625 6.75a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Zm4.875 0A.75.75 0 0 1 8.25 6h12a.75.75 0 0 1 0 1.5h-12a.75.75 0 0 1-.75-.75ZM2.625 12a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0ZM7.5 12a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 0 1.5h-12A.75.75 0 0 1 7.5 12Zm-4.875 5.25a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Zm4.875 0a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 0 1.5h-12a.75.75 0 0 1-.75-.75Z"
                            clip-rule="evenodd" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6" v-else>
                        <path fill-rule="evenodd"
                            d="M13.06 12l5.22-5.22a.75.75 0 0 0-1.06-1.06L12 10.94 6.78 5.72a.75.75 0 0 0-1.06 1.06L10.94 12l-5.22 5.22a.75.75 0 0 0 1.06 1.06L12 13.06l5.22 5.22a.75.75 0 0 0 1.06-1.06L13.06 12Z"
                            clip-rule="evenodd" />
                    </svg>
                </button>
            </div>
        </div>
        <ul :class="{ '-translate-y-[130%] -top-[130%]': !isVisible, 'translate-y-0 top-28': isVisible }"
            class="py-5 spacey-y-2 fixed sm:relative  sm:translate-y-0 bg-white transition-all duration-500">

            <li class="py-1" v-for="section in sections">
                <a :href="'#'+section.slug" @click="toggleOutlineMenu" 
                    class="block px-4 py-3 bg-neutral-50 rounded-lg hover:bg-blue-500 hover:text-white"
                    v-html="section.label">
                </a>
            </li>
        </ul>
    </div>

</template>
<script setup>
import { ref, watch } from 'vue'

// props for content sections
const props = defineProps({
    sections: Array,
    globalNavVisible: Boolean
})

const isVisible = ref(false)

const toggleOutlineMenu = () => {
    isVisible.value = !isVisible.value
}

</script>