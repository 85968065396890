<template>
    <select @update:model-value="apply" v-model="selection" class="py-2 px-4 pr-8 bg-white rounded-md border border-neutral-400 text-neutral-700">
        <option value="">{{ placeholder }}</option>
        <option v-for="(label, value) in options" :value="value">{{ label }}</option>
    </select>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
    base: { type: String, required: true },
    placeholder: { type: String, required: true },
    options: { type: Object, required: true },
    current: { type: String, default: '' },
});

const selection = ref(props.current);

function apply(value) {
    const baseUrl = props.base.replace(/\/$/, '') + '/';

    window.location = baseUrl + value;
}
</script>
