<template>
    <div class="relative" :class="wrapperClass">

        <div>
            <textarea :class="{'hidden': field.type == 'honeypot', 'text-white border-b-white' : foregroundIsLight, 'text-neutral-900 border-b-neutral-900': !foregroundIsLight}" class="border-transparent focus:border-b focus:border-t-0 focus:border-r-0 focus:border-l-0 focus:border-white focus:ring-0  w-full bg-transparent border-b outline-none py-1 px-3 h-20 text-sm focus:outline-none placeholder-transparent peer" :placeholder="field.label" v-if="field.type == 'textarea'" v-model="model" @update:model-value="bubble"></textarea>

            <input :class="{'hidden': field.type == 'honeypot', 'text-white border-b-white' : foregroundIsLight, 'text-neutral-900 border-b-neutral-900': !foregroundIsLight}" class=" border-transparent focus:border-b focus:border-t-0 focus:border-r-0 focus:border-l-0 focus:border-white focus:ring-0 w-full bg-transparent border-b outline-none py-1 px-3 text-sm focus:outline-none placeholder-transparent peer" :placeholder="field.label" v-if="field.type != 'textarea'" :type="field.type" v-model="model" @update:model-value="bubble" />

            <label :class="{'hidden': field.type == 'honeypot', 'text-white border-b-white' : foregroundIsLight, 'text-neutral-900 border-b-neutral-900': !foregroundIsLight}" class="border-transparent focus:border-b focus:border-t-0 focus:border-r-0 focus:border-l-0 focus:border-white focus:ring-0 absolute -top-4 px-3 opacity-80 outline-none block text-xs peer-placeholder-shown:top-1 peer-placeholder-shown:opacity-100 peer-focus:-top-4 transition-all">{{ field.label }} <span v-if="field.isRequired" class="pl-1" :class="{'text-white':foregroundIsLight, 'text-red-600': !foregroundIsLight}">*</span></label>
        </div>

        <div class="bg-red-500/10">{{ field.error }}</div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    field: { type: Object, required: true },
    modelValue: { type: [String, Number] },
    foregroundIsLight: { type: Boolean, default: true },
});

const model = ref(props.modelValue);

const wrapperClass = computed(() => {
    const classes = ['contact-form-field'];

    if (props.field.cssClass) {
        classes.push(props.field.cssClass);
    }

    if (props.field.layoutGridColumnSpan) {
        classes.push('span-' + props.field.layoutGridColumnSpan);
    }

    return classes;
});

function bubble(value) {
    emit('update:modelValue', value);
}
</script>

<style>
.contact-form-field.span-3 {
    @apply col-span-12 sm:col-span-3;
}
.contact-form-field.span-4 {
    @apply col-span-12 sm:col-span-4;
}
.contact-form-field.span-6 {
    @apply col-span-12 sm:col-span-6;
}
.contact-form-field.span-8 {
    @apply col-span-12 sm:col-span-8;
}
.contact-form-field.span-12 {
    @apply col-span-12 sm:col-span-12;
}
</style>
