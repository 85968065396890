<template>
    <div class="grid grid-cols-1 md:grid-cols-3 items-center">
        <div class="col-span-2 sm:pr-16">

            <template v-for="(tab, i) in tabs">
                <div class="cursor-pointer border-b border-neutral-300 hover:bg-slate-100 group"
                    :class="{ 'border-t': i == 0, 'bg-slate-100': tabIsActive(i) }" @click="setActiveTab(i)"
                    @mouseover="setActiveTab(i)">
                    <div class="flex items-center py-2 sm:py-0">
                        <div class="flex-shrink-0"><img :src="tab.image.preview" :alt="tab.image.alt"
                                class="w-16 sm:w-28 sm:h-28" /></div>
                        <h4 class="ml-8 translate-y-1 text-2xl sm:text-4xl xl:text-[2.6em] leading-[1em] xl:leading-2 uppercase font-headline"
                            v-text="tab.label"></h4>
                    </div>
                </div>
            </template>
        </div>
        <div class="col-span-1">
            <template v-for="(tab, i) in tabs">
                <div class="p-10 user-content" :class="{ 'hidden': !tabIsActive(i) }">
                    <h5 class="h5" v-text="tab.headline"></h5>
                    <div v-html="tab.content" class="!text-[16px] text-neutral-600 mt-3"></div>
                    <div>
                        <a :href="tab.button.url" class="button" v-text="tab.button.label"></a>
                        <!-- <?php $item->get('button')->display(['class' => 'button']) ?> -->
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
    tabs: {
        type: Array,
        required: true
    },
});

const activeTab = ref(0);

function tabIsActive(i) {
    return i == activeTab.value;
}

function setActiveTab(i) {
    activeTab.value = i;
}

</script>